import React from "react";
import { Link } from "react-scroll";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const AboutContainer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <motion.div
      ref={ref}
      style={{ opacity: inView ? 1 : 0, transition: "opacity 750ms ease-in" }}
    >
      <div className="about container section" id="about">
        <h1>My Story</h1>
        <div className="about-inner-container">
          <div className="summary">
            <p>
              Hello, I'm Herman, a full-stack developer based in San Francisco.
            </p>
            <p>
              I'm a big believer in being a lifelong learner and that anything
              is possible with enough determination and perserverance. I started
              my journey in programming back in 2019 and since then I have had
              the privilege to collaborate with awesome developers and work on
              big proejects. What I love most about programming is the ability
              to create and build software solutions through the code I write. I
              intend to apply the knowledge I gained thus far to companies who
              see value in the work I do.
            </p>
            <p>
              Hobbies include: cooking 🍳, long drives 🚗, reading 📚, learning
              new technologies 🧠, exercising 💪, and traveling 🗺️.
            </p>
            <p>
              Feel free to
              <span className="contact-link">
                <Link to="contact"> contact me </Link>
              </span>
              if you want to connect 🙂.
            </p>
          </div>
          <div className="image-container">
            <div className="image-overlay"></div>
            <img src="/assets/images/herman.PNG" alt="herman" />
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default AboutContainer;
