import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-scroll";
import BIRDS from "vanta/dist/vanta.birds.min";

const LandingContainer = () => {
  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 200.0,
          scale: 1.0,
          scaleMobile: 1.0,
          backgroundColor: "#1b1b1b",
          color1: "blue",
          colorMode: "variance",
          quantity: 3.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="landing-section" id="home" ref={myRef}>
      <div className="landing-overlay"></div>
      <div className="landing-inner">
        <h1 className="title">Hi! 👋🏻🧑🏻‍💻</h1>
        <h1 className="title">I'm Herman,</h1>
        <p>
          a full-stack developer with a focus in front-end development,
          specializing in building awesome web apps
        </p>
        <Link to="about">
          <button>About Me</button>
        </Link>
      </div>
    </div>
  );
};

export default LandingContainer;
