import React from "react";
import data from "../constants/services.json";
import { ServiceCard } from "../components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ServicesContainer = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <motion.div
      ref={ref}
      style={{ opacity: inView ? 1 : 0, transition: "opacity 750ms ease-in" }}
    >
      <div className="service container section" id="services">
        <h1> Services </h1>
        <div className="services-container">
          {data.map((item, i) => (
            <ServiceCard
              icon={item.icon}
              title={item.service}
              description={item.description}
              technologies={item.technologies}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default ServicesContainer;

/*
previous
        {data.map((item, i) => (
          <div key={item.id} className="service-box box">
            <img src={item.icon} alt="img" className="icon" />
            <h3> {item.service} </h3>
            <p> {item.description} </p>
          </div>
        ))}

*/
