import React from "react";
import data from "../constants/projects.json";
// import Tooltip from "@material-ui/core/Tooltip";
import { Card } from "../components";
import { useInView } from "react-intersection-observer";
import { motion } from "framer-motion";

const ProjectsContainer = () => {
  const goToLink = (url) => {
    console.log("clicked");
    window.open(url, "_blank");
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: "-100px 0px",
  });

  return (
    <motion.div
      ref={ref}
      style={{ opacity: inView ? 1 : 0, transition: "opacity 750ms ease-in" }}
    >
      <div className="projects container section" id="projects">
        <h1> Projects </h1>
        <div className="projects-container">
          {data.map((item, i) => {
            return (
              <div className="project">
                <Card
                  title={item.title}
                  summary={item.summary}
                  image={item.image}
                  image2={item.image2}
                  demo={item.demo}
                  git={item.git}
                  background={item.background}
                />
              </div>
            );
          })}
        </div>
      </div>
    </motion.div>
  );
};

export default ProjectsContainer;
