import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    minHeight: 250,
    width: 300,
    margin: "1rem",
  },
  media: {
    // height: 140,
    height: 250,
  },
  upper: {
    height: 200,
  },
});

export default function MediaCard({
  title,
  summary,
  image,
  image2,
  demo,
  git,
  background,
}) {
  const classes = useStyles();

  const goToLink = (url) => {
    if (url !== false) {
      window.open(url, "_blank");
    } else {
      return;
    }
  };

  return (
    <Card className={classes.root}>
      <CardActionArea onClick={() => goToLink(demo)}>
        <CardMedia className={classes.media} image={background} />
        <CardContent className={classes.upper}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <p> {summary} </p>
        </CardContent>
      </CardActionArea>
      <CardActions>
        {demo !== false && (
          <Button size="small" color="blue" onClick={() => goToLink(demo)}>
            Demo
          </Button>
        )}
        <Button size="small" color="blue" onClick={() => goToLink(git)}>
          GitHub
        </Button>
      </CardActions>
    </Card>
  );
}
