import React, { useState, useEffect, useRef } from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import YouTubeIcon from "@material-ui/icons/YouTube";
import GitHubIcon from "@material-ui/icons/GitHub";
import TwitterIcon from "@material-ui/icons/Twitter";
import CopyrightIcon from "@material-ui/icons/Copyright";
import BIRDS from "vanta/dist/vanta.birds.min";

const FooterContainer = () => {
  const scrollToTop = () => {
    window.scroll(0, 0);
  };

  const [vantaEffect, setVantaEffect] = useState(0);
  const myRef = useRef(null);
  useEffect(() => {
    if (!vantaEffect) {
      setVantaEffect(
        BIRDS({
          el: myRef.current,
          mouseControls: true,
          touchControls: true,
          gyroControls: false,
          minHeight: 200.0,
          minWidth: 300.0,
          scale: 3.0,
          scaleMobile: 2.0,
          backgroundColor: "#1b1b1b",
          color1: "blue",
          colorMode: "variance",
          quantity: 5.0,
        })
      );
    }
    return () => {
      if (vantaEffect) vantaEffect.destroy();
    };
  }, [vantaEffect]);

  return (
    <div className="footer">
      <div className="footer-bottom" ref={myRef}>
        <div className="icon-container">
          <ArrowForwardIosIcon
            className="arrow-up-icon"
            onClick={scrollToTop}
          />
        </div>
        <div className="rights-container flex-row">
          <p>
            <CopyrightIcon className="copyright-icon" />
            2021 Herman Liu
          </p>
          <p>All rights reserved</p>
        </div>
        <div className="social-container flex-row">
          {/* LinkedIn, YouTube, GitHub */}
          <a hRef="https://www.linkedin.com/in/hermanliu168/" target="_blank">
            <LinkedInIcon className="social-icon" />
          </a>
          <a
            hRef="https://www.youtube.com/channel/UCFHqm4if2I5yJVFJ6xG6FSA"
            target="_blank"
          >
            <YouTubeIcon className="social-icon" />
          </a>
          <a hRef="https://twitter.com/Hermsicles" target="_blank">
            <TwitterIcon className="social-icon" />
          </a>
          <a hRef="https://github.com/hermsicle" target="_blank">
            <GitHubIcon className="social-icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default FooterContainer;
